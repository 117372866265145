import "./NavgationBar.scss";
import { Container, Stack } from "@mui/material";
import logo from "assets/logo.png";
import AuthService from "services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import BasicMenu from "components/atoms/BasicMenu";

export default function NavgationBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (pathname === "/") return <></>;

  return (
    <header>
      <div className="navbar__container">
        <Container>
          <ul>
            <li>
              <div className="logo">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard")}
                  src={logo}
                  alt="logo"
                />
              </div>
            </li>
            <li>
              <Link
                to="/dashboard"
                className={`${pathname === "/dashboard" ? "active" : ""}`}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/active-orders"
                className={`${
                  pathname === "/orders" ||
                  pathname === "/active-orders" ||
                  pathname === "/failed-orders" ||
                  pathname === "/today-orders"
                    ? "active"
                    : ""
                }`}
              >
                Orders
              </Link>
            </li>

            <li>
              <Link
                to="/home-page-banner"
                className={`${
                  pathname === "/home-page-banner" ? "active" : ""
                }`}
              >
                Banners
              </Link>
            </li>

            <li>
              <Link
                to="/account-management"
                className={`${
                  pathname === "/account-management" ||
                  pathname === "/accounts-with-most-orders"
                    ? "active"
                    : ""
                }`}
              >
                Users
              </Link>
            </li>
            {/* <li>
              <Link to="/accounts-with-most-orders">Hot Buyers</Link>
            </li> */}
            <li>
              <Link
                to="/product-management"
                className={`${
                  pathname === "/product-management" ? "active" : ""
                }`}
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="/deals-management"
                className={`${
                  pathname === "/deals-management" ? "active" : ""
                }`}
              >
                Deals
              </Link>
            </li>
            <li>
              <Link
                to="/category-management"
                className={`${
                  pathname === "/category-management" ? "active" : ""
                }`}
              >
                Category
              </Link>
            </li>
            <li>
              <Link
                to="/subcategory-management"
                className={`${
                  pathname === "/subcategory-management" ? "active" : ""
                }`}
              >
                Sub-Category
              </Link>
            </li>
            <li>
              <Link
                to="/campaigns"
                className={`${pathname === "/campaigns" ? "active" : ""}`}
              >
                Campaigns
              </Link>
            </li>
            {/* <li>
              <NewManu
                list={[
                  {
                    text: (
                      <a
                        href="/delivery-fee"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/delivery-fee");
                        }}
                      >
                        Delivery Fee Management
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/inventory"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/inventory");
                        }}
                      >
                        Inventory
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/popular-searches"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/popular-searches");
                        }}
                      >
                        Search Terms
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/home-page-banner"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/home-page-banner");
                        }}
                      >
                        Home Page Banner
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/faq-items"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/faq-items");
                        }}
                      >
                        Faq Items
                      </a>
                    ),
                  },
                ]}
                avatar={false}
                color="#ffffff"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Link to="#">
                    Content <br /> Management
                  </Link>
                  <FaAngleDown />
                </Stack>
              </NewManu>
            </li> */}
            {/* 
            <li>
              <NewManu
                list={[
                  {
                    text: (
                      <a
                        href="/account-management"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/account-management");
                        }}
                      >
                        Account Management
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/accounts-with-most-orders"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/accounts-with-most-orders");
                        }}
                      >
                        Accounts With Most Orders
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/send-push-notification"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/send-push-notification");
                        }}
                      >
                        Send Push Notification
                      </a>
                    ),
                  },
                ]}
                avatar={false}
                color="#ffffff"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Link to="#">
                    Account <br /> Management
                  </Link>
                  <FaAngleDown />
                </Stack>
              </NewManu>
            </li> */}

            {/* <li>
              <NewManu
                list={[
                  {
                    text: (
                      <a
                        href="/product-management"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/product-management");
                        }}
                      >
                        Product Management
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/deals-management"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/deals-management");
                        }}
                      >
                        Deals Management
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/category-management"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/category-management");
                        }}
                      >
                        Category Management
                      </a>
                    ),
                  },
                  {
                    text: (
                      <a
                        href="/subcategory-management"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/subcategory-management");
                        }}
                      >
                        Sub Category Management
                      </a>
                    ),
                  },
                ]}
                avatar={false}
                color="#ffffff"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Link to="#">
                    Catalogue <br /> Management
                  </Link>
                  <FaAngleDown />
                </Stack>
              </NewManu>
            </li> */}
            {/* <li>
              <NewManu
                list={[
                  {
                    text: (
                      <a
                        href="/campaigns"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/campaigns");
                        }}
                      >
                        Campaigns
                      </a>
                    ),
                  },
                ]}
                avatar={false}
                color="#ffffff"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Link to="#">
                    Campaign <br /> Management
                  </Link>
                  <FaAngleDown />
                </Stack>
              </NewManu>
            </li> */}

            <li>
              <BasicMenu
                list={[
                  {
                    text: (
                      <Link
                        to="/delivery-fee"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/delivery-fee");
                        }}
                      >
                        Delivery Fee
                      </Link>
                    ),
                  },

                  {
                    text: (
                      <Link
                        to="/popular-searches"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/popular-searches");
                        }}
                      >
                        Search Terms
                      </Link>
                    ),
                  },
                  {
                    text: (
                      <Link
                        to="/inventory"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/inventory");
                        }}
                      >
                        Inventory
                      </Link>
                    ),
                  },
                  {
                    text: (
                      <Link
                        to="/faq-items"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/faq-items");
                        }}
                      >
                        Faq Items
                      </Link>
                    ),
                  },
                  {
                    text: (
                      <Link
                        to="/send-push-notification"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        className="no-link-styles"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/send-push-notification");
                        }}
                      >
                        Notifications
                      </Link>
                    ),
                  },
                  {
                    text: "Logout",
                    onClick: () => AuthService.logout(),
                  },
                ]}
                avatar={false}
                color="#ffffff"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Link
                    to="#"
                    className={`${
                      pathname.includes("/delivery-fee") ||
                      pathname.includes("/popular-searches") ||
                      pathname.includes("/inventory") ||
                      pathname.includes("/faq-items") ||
                      pathname.includes("/send-push-notification")
                        ? "active"
                        : ""
                    }`}
                  >
                    Settings
                  </Link>
                  <FaAngleDown />
                </Stack>
              </BasicMenu>
            </li>
          </ul>
        </Container>
      </div>
    </header>
  );
}
