export const generatePrintableOrders = (orders: any[]) => `
    <html>
      <head>
        <title>Print Orders</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
      <div>
            ${orders
              .map(
                (order) => `
                <div style="margin-bottom: 20px">
                    ${order?.orderId} <br />
                    ${order?.firstName} ${order.lastName} <br />
                    ${order?.phone} <br />
                    ${order?.email} <br />
                    ${order?.shippingAddress?.addressTitle} <br /> 
                    ${order?.shippingAddress?.addressDetail} <br />
                </div>
              `
              )
              .join("")}
            </div>
        <script>
          window.print();
        </script>
      </body>
    </html>
  `;
